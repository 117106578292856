import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import LogOutModal from "components/LogOutModal/LogOutModal";
import NotificationBar from "components/NotificationBar";
import OfflineModal from "components/OfflineModal";
import routes, { AppRoute } from "core/routes";
import theme from "core/Theme";
import { setNotification } from "pages/Notification/Notification.action";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { setNewSocket, toggleUserNetworkStatus } from "store/commonActions";
import { initializeSocket } from "utils";

function App() {
  const dispatch = useDispatch();
  const socketConnection = useSelector((state) => state.common.newSocket);

  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "e0u75p1uzu");
  }, []);

  useEffect(() => {
    initializeSocket().then((value) => {
      if (!Object.keys(socketConnection).length) {
        dispatch(setNewSocket(value));
      }
    });
  }, [dispatch, socketConnection]);

  useEffect(() => {
    if (Object.keys(socketConnection).length) {
      socketConnection?.on("holder_credential_issue", ({ data }) => {
        dispatch(setNotification(data));
      });
      socketConnection?.on("holder_revoke", ({ data }) => {
        dispatch(setNotification(data));
      });
    }
    //eslint-disable-next-line
  }, [dispatch, socketConnection]);

  useEffect(() => {
    window.addEventListener("load", () => {
      window.addEventListener("online", () => {
        dispatch(toggleUserNetworkStatus(true));
      });
      window.addEventListener("offline", () => {
        dispatch(toggleUserNetworkStatus(false));
      });
    });
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <NotificationBar />
          <LogOutModal />
          <OfflineModal />
          <Switch>
            <Switch>
              {routes.map((route) => (
                <AppRoute key={route.path} {...route} />
              ))}
            </Switch>
          </Switch>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

export default App;
