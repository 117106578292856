import { Paper, Tab, Tabs, makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import ImageCropper from "components/ImageCropper/ImageCropper";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { LOGO_MAX_SIZE, toBase64 } from "utils";

const useStyles = makeStyles((theme) => ({
  fileInput: {
    display: "none",
  },
  fileInputBox: {
    border: "solid 0.5px #9f9f9f",
    borderRadius: "4px",
    padding: "10px 15px",
  },
  fileInputLable: {
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "500",
    color: "#083f85",
    marginBottom: "0",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    "& span": {
      color: "#f00",
    },
  },
  fieldError: {
    color: "red",
    fontSize: "12px",
    marginTop: "6px",
  },
  fileName: {
    fontSize: "15px",
    color: "#616161",
  },
  thumbInner: {
    width: "200px",
    marginTop: "10px",
    position: "relative",
  },
  img: {
    display: "block",
    width: "100%",
    height: "100%",
  },
  fileNameWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  clearIcon: {
    padding: 0,
  },
  thumbButton: {
    position: "absolute",
    right: 10,
    bottom: 10,
    background: "rgba(0,0,0,.8)",
    color: "#fff",
    border: 0,
    borderRadius: ".325em",
    cursor: "pointer",
  },
  tabMainWrappers: {
    marginBottom: 10,
  },
}));

const fileTypes = ["image/jpeg", "image/jpg", "image/png"];
const aspectRatios = {
  0: 16 / 9,
  1: 1,
  2: 4 / 5,
};

export default function FileInput({ fieldData, fieldName }) {
  const classes = useStyles();
  const {
    setValue,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const [files, setFiles] = useState(null);
  const [customError, setCustomError] = useState("");
  const defaultImage = fieldData?.value || watch(fieldName);
  const [croppedImage, setCroppedImage] = useState();
  const [imageSrc, setImageSrc] = useState("");
  const [tab, setTab] = useState(1);
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    aspect: aspectRatios[tab],
  });

  const setFileAndOpenCropper = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      setImageSrc(reader.result);
      setOpen(true);
    });
    reader.readAsDataURL(file);
  };

  const handleEdit = (ev, file) => {
    ev.preventDefault();
    ev.stopPropagation();
    setFileAndOpenCropper(file);
  };

  const imagePreview = files ? (
    <div className={classes.thumbInner}>
      <img
        src={croppedImage?.preview || files.preview}
        className={classes.img}
        alt={files.name}
      />
      <button
        type="button"
        className={classes.thumbButton}
        onClick={(ev) => handleEdit(ev, files)}
      >
        Edit
      </button>
    </div>
  ) : (
    defaultImage && (
      <div className={classes.thumbInner}>
        <img
          src={defaultImage}
          className={classes.img}
          alt={"Preview of " + fieldData.fname}
        />
      </div>
    )
  );

  const handleCrearField = (ev) => {
    ev.preventDefault();
    setFiles(null);
    setValue(fieldName, defaultImage || "", { shouldDirty: true });
  };

  const handleCrearInput = (ev) => {
    ev.target.value = "";
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setCrop({ unit: "%", aspect: aspectRatios[newValue] });
  };

  const handleCroppedImage = async (image) => {
    setCroppedImage(image);
    const base64Image = await toBase64(image);
    setValue(fieldName, base64Image);
  };

  return (
    <div>
      <ImageCropper
        crop={crop}
        setCrop={setCrop}
        src={imageSrc}
        setSrc={setImageSrc}
        open={open}
        setOpen={setOpen}
        setFiles={handleCroppedImage}
      >
        {files && (
          <Paper square className={classes.tabMainWrappers}>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Horizontal (16/9)" />
              <Tab label="Square (1/1)" />
              <Tab label="Vertical (4/5)" />
            </Tabs>
          </Paper>
        )}
      </ImageCropper>
      <Controller
        name={fieldName}
        control={control}
        rules={
          fieldData.is_mandatory ? { required: "This field is required" } : {}
        }
        defaultValue=""
        render={({ field, fieldState }) => (
          <>
            <div className={classes.fileInputBox}>
              <InputLabel
                htmlFor={fieldName}
                className={classes.fileInputLable}
              >
                Choose File
                {files?.name && (
                  <IconButton
                    className={classes.clearIcon}
                    onClick={handleCrearField}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
              </InputLabel>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                size="1000000"
                hidden
                id={fieldName}
                className={classes.fileInput}
                onClick={handleCrearInput}
                onChange={async (event) => {
                  let files = event.target.files[0];

                  if (files?.size > LOGO_MAX_SIZE) {
                    setCustomError("The file size should be less than 1 MB.");
                    return;
                  } else if (!fileTypes.includes(files?.type)) {
                    setCustomError(
                      "The file should be in .png or .jpeg format only."
                    );
                    return;
                  }
                  Object.assign(files, {
                    preview: URL.createObjectURL(files),
                  });

                  setCustomError("");
                  setFiles(files);
                  const base64 = await toBase64(files);
                  field.onChange(base64);
                  setFileAndOpenCropper(files);
                }}
              />
            </div>
            {(customError || fieldState?.error?.message) && (
              <Typography
                variant="body1"
                component="p"
                className={classes.fieldError}
              >
                {customError || fieldState?.error?.message}
              </Typography>
            )}
          </>
        )}
      />
      {imagePreview}
    </div>
  );
}
