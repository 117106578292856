import makeStyles from "@material-ui/core/styles/makeStyles";
import Logo from "assets/images/pcmc-logo.png";
import React from "react";

const AppLogo = (props) => {
  const useStyles = makeStyles((theme) => ({
    mainLogo: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: 30,
    },
  }));
  const classes = useStyles();
  return (
    <div
      className={`${classes.mainLogo} ${
        props.customClass ? props.customClass : " "
      }`}
    >
      <img alt="logo" src={Logo} width="200" height="45" />
    </div>
  );
};

export default React.memo(AppLogo);
