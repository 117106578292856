export function objectsAreSame(x, y) {
  let objectsAreSame = true;
  for (const propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

export function arraysAreSame(array1, array2) {
  const is_same =
    array1.length === array2.length &&
    array1?.every(function (element, index) {
      return element === array2[index];
    });
  return is_same;
}
