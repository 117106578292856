import {
  GET_ISSUER_LIST_BEGIN,
  GET_ISSUER_LIST_SUCCESS,
  GET_ISSUER_LIST_FAILURE,
  GET_CREDENTIAL_LIST_BEGIN,
  GET_CREDENTIAL_LIST_SUCCESS,
  GET_CREDENTIAL_LIST_FAILURE,
  SELECT_ISSUER,
  SELECT_CREDENTIAL,
  LOAD_MORE_ISSUER_BEGIN,
  LOAD_MORE_ISSUER_SUCCESS,
  LOAD_MORE_ISSUER_FAILURE,
  CLEAR_CREDENTIAL_REDUCER,
  CREDENTIAL_PREVIEW_BEGIN,
  CREDENTIAL_PREVIEW_SUCCESS,
  CREDENTIAL_PREVIEW_FAILURE,
  DOWNLOAD_CREDENTIAL_BEGIN,
  DOWNLOAD_CREDENTIAL_FAILURE,
  DOWNLOAD_CREDENTIAL_SUCCESS,
  SHARE_CREDENTIAL_BEGIN,
  SHARE_CREDENTIAL_SUCCESS,
  SHARE_CREDENTIAL_FAILURE,
  GET_VERIFICATION_OVERVIEW_BEGIN,
  GET_VERIFICATION_OVERVIEW_SUCCESS,
  GET_VERIFICATION_OVERVIEW_FAILURE,
  GET_RECENTLY_VERIFIED_CREDENTIAL_BEGIN,
  GET_RECENTLY_VERIFIED_CREDENTIAL_FAILURE,
  GET_RECENTLY_VERIFIED_CREDENTIAL_SUCCESS,
  GET_LAST_VERIFIED_DATE_BEGIN,
  GET_LAST_VERIFIED_DATE_SUCCESS,
  GET_LAST_VERIFIED_DATE_FAILURE,
  ADD_CREDENTIAL_IMAGE_BEGIN,
  ADD_CREDENTIAL_IMAGE_SUCCESS,
  ADD_CREDENTIAL_IMAGE_FAILURE,
  GET_SUBJECT_DATA_FIELD_BEGIN,
  GET_SUBJECT_DATA_FIELD_SUCCESS,
  GET_SUBJECT_DATA_FIELD_FAILURE,
  SEND_DRC_REQUEST_BEGIN,
  SEND_DRC_REQUEST_SUCCESS,
  SEND_DRC_REQUEST_FAILURE,
} from "./Credentials.constant";

const initState = {
  issuerList: {
    loading: false,
    error: "",
    response: {},
  },
  credentialsList: {
    loading: false,
    error: "",
    response: [],
  },
  previewCredential: {
    loading: false,
    error: "",
    response: [],
  },
  overviewStats: {
    loading: false,
    error: "",
    response: {},
  },
  logs: {
    loading: false,
    error: "",
    response: [],
  },
  selectedIssuer: {},
  selectedCredential: {},
  lastVerifiedDate: {
    loading: false,
    error: "",
    response: {},
  },
  addCredentialImage: {
    loading: false,
    error: "",
    response: {},
  },
  subjectDataField: { loading: false, error: "", response: {} },
  sendDRCRequest: { loading: false, error: "", response: {} },
};

const CredentialReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_SUBJECT_DATA_FIELD_BEGIN:
      return {
        ...state,
        subjectDataField: {
          ...state.subjectDataField,
          loading: true,
        },
      };
    case GET_SUBJECT_DATA_FIELD_SUCCESS:
      return {
        ...state,
        subjectDataField: {
          ...state.subjectDataField,
          loading: false,
          response: action.payload,
        },
      };
    case GET_SUBJECT_DATA_FIELD_FAILURE:
      return {
        ...state,
        subjectDataField: {
          ...state.subjectDataField,
          loading: false,
          error: action.payload,
        },
      };

    case SEND_DRC_REQUEST_BEGIN:
      return {
        ...state,
        sendDRCRequest: {
          ...state.sendDRCRequest,
          loading: true,
        },
      };
    case SEND_DRC_REQUEST_SUCCESS:
      return {
        ...state,
        sendDRCRequest: {
          ...state.sendDRCRequest,
          loading: false,
          response: action.payload,
        },
      };
    case SEND_DRC_REQUEST_FAILURE:
      return {
        ...state,
        sendDRCRequest: {
          ...state.sendDRCRequest,
          loading: false,
          error: action.payload,
        },
      };

    case GET_ISSUER_LIST_BEGIN:
      return {
        ...state,
        issuerList: {
          ...state.issuerList,
          loading: true,
        },
      };
    case GET_ISSUER_LIST_SUCCESS:
      return {
        ...state,
        issuerList: {
          ...state.issuerList,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case GET_ISSUER_LIST_FAILURE:
      return {
        ...state,
        issuerList: {
          ...state.issuerList,
          loading: false,
          response: {},
          error: action.payload,
        },
      };
    case LOAD_MORE_ISSUER_BEGIN:
      return {
        ...state,
        issuerList: {
          ...state.issuerList,
          loading: true,
          error: "",
        },
      };
    case LOAD_MORE_ISSUER_SUCCESS:
      return {
        ...state,
        issuerList: {
          ...state.issuerList,
          loading: false,
          response: {
            ...state.issuerList.response,
            data: [...state.issuerList.response.data, ...action.payload.data],
          },
          error: "",
        },
      };
    case LOAD_MORE_ISSUER_FAILURE:
      return {
        ...state,
        issuerList: {
          ...state.issuerList,
          loading: false,
          error: action.payload,
        },
      };
    case GET_CREDENTIAL_LIST_BEGIN:
      return {
        ...state,
        credentialsList: {
          ...state.credentialsList,
          loading: true,
        },
      };
    case GET_CREDENTIAL_LIST_SUCCESS:
      return {
        ...state,
        credentialsList: {
          ...state.credentialsList,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case GET_CREDENTIAL_LIST_FAILURE:
      return {
        ...state,
        credentialsList: {
          ...state.credentialsList,
          loading: false,
          response: [],
          error: action.payload,
        },
      };
    case SELECT_ISSUER:
      return {
        ...state,
        selectedIssuer: action.payload,
      };
    case SELECT_CREDENTIAL:
      return {
        ...state,
        selectedCredential: action.payload,
      };
    case CLEAR_CREDENTIAL_REDUCER: {
      return initState;
    }
    case CREDENTIAL_PREVIEW_BEGIN:
      return {
        ...state,
        previewCredential: {
          ...state.previewCredential,
          loading: true,
        },
      };
    case CREDENTIAL_PREVIEW_SUCCESS:
      return {
        ...state,
        previewCredential: {
          ...state.previewCredential,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case CREDENTIAL_PREVIEW_FAILURE:
      return {
        ...state,
        previewCredential: {
          ...state.previewCredential,
          loading: false,
          response: [],
          error: action.payload,
        },
      };
    case GET_VERIFICATION_OVERVIEW_BEGIN:
      return {
        ...state,
        overviewStats: {
          ...state.overviewStats,
          loading: true,
        },
      };
    case GET_VERIFICATION_OVERVIEW_SUCCESS:
      return {
        ...state,
        overviewStats: {
          ...state.overviewStats,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case GET_VERIFICATION_OVERVIEW_FAILURE:
      return {
        ...state,
        overviewStats: {
          ...state.overviewStats,
          loading: false,
          response: [],
          error: action.payload,
        },
      };
    case GET_RECENTLY_VERIFIED_CREDENTIAL_BEGIN:
      return {
        ...state,
        logs: {
          ...state.logs,
          loading: true,
        },
      };
    case GET_RECENTLY_VERIFIED_CREDENTIAL_SUCCESS:
      return {
        ...state,
        logs: {
          ...state.logs,
          loading: false,
          response: action.payload,
          error: "",
        },
      };
    case GET_RECENTLY_VERIFIED_CREDENTIAL_FAILURE:
      return {
        ...state,
        logs: {
          ...state.logs,
          loading: false,
          response: [],
          error: action.payload,
        },
      };
    case GET_LAST_VERIFIED_DATE_BEGIN:
      return {
        ...state,
        lastVerifiedDate: {
          ...state.lastVerifiedDate,
          loading: true,
        },
      };
    case GET_LAST_VERIFIED_DATE_SUCCESS:
      return {
        ...state,
        lastVerifiedDate: {
          ...state.lastVerifiedDate,
          loading: false,
          response: action.payload,
        },
      };
    case GET_LAST_VERIFIED_DATE_FAILURE:
      return {
        ...state,
        lastVerifiedDate: {
          ...state.lastVerifiedDate,
          loading: false,
          error: action.payload,
        },
      };
    case ADD_CREDENTIAL_IMAGE_BEGIN:
      return {
        ...state,
        addCredentialImage: {
          ...state.addCredentialImage,
          loading: true,
        },
      };
    case ADD_CREDENTIAL_IMAGE_SUCCESS:
      return {
        ...state,
        addCredentialImage: {
          ...state.addCredentialImage,
          loading: false,
          response: action.payload,
        },
      };
    case ADD_CREDENTIAL_IMAGE_FAILURE:
      return {
        ...state,
        addCredentialImage: {
          ...state.addCredentialImage,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default CredentialReducer;

const initialState = {
  downloadCredential: {
    loading: false,
    error: "",
    response: {},
  },
  shareCredential: {
    loading: false,
    error: "",
    response: {},
  },
  getCredLink: {
    loading: false,
    error: "",
    response: {},
  },
};

export const CredentialsActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_CREDENTIAL_BEGIN:
      return {
        ...state,
        downloadCredential: {
          ...state.downloadCredential,
          loading: true,
        },
      };
    case DOWNLOAD_CREDENTIAL_SUCCESS:
      return {
        ...state,
        downloadCredential: {
          ...state.downloadCredential,
          loading: false,
          response: action.payload,
        },
      };
    case DOWNLOAD_CREDENTIAL_FAILURE:
      return {
        ...state,
        downloadCredential: {
          ...state.downloadCredential,
          loading: false,
          error: action.payload,
        },
      };
    case SHARE_CREDENTIAL_BEGIN:
      return {
        ...state,
        shareCredential: {
          ...state.shareCredential,
          loading: true,
        },
      };
    case SHARE_CREDENTIAL_SUCCESS:
      return {
        ...state,
        shareCredential: {
          ...state.shareCredential,
          loading: false,
          response: action.payload,
        },
      };
    case SHARE_CREDENTIAL_FAILURE:
      return {
        ...state,
        shareCredential: {
          ...state.shareCredential,
          loading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
