import React from "react";
import { Typography, Divider, makeStyles } from "@material-ui/core";
// import GoogleLogin from "./GoogleLogin/GoogleLogin";
// import MicrosoftSocialLogin from "./MicrosoftLogin/MicrosoftLogin";
// import LinkedInLogin from "./LinkedInLogin/LinkedInLogin";

const useStyle = makeStyles({
  socialLoginMain: {
    // margin: "10px 0",
    "& span": {
      fontWeight: "600",
      marginBottom: 5,
      // color: " #616161",
    },
  },
  my15: {
    margin: "15px 0",
  },
  icons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px 0",
    "& button": {
      marginRight: 11,
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
});

const SocialLogin = ({ title = "login" }) => {
  const classes = useStyle();
  return (
    <div className={classes.socialLoginMain}>
      <Typography
        variant="body2"
        component="span"
        align="center"
        color="secondary"
      >
        Also You Can {title} Below
      </Typography>
      <div className={classes.icons}>
        {/* <GoogleLogin /> */}
        {/* <LinkedInLogin /> */}
        {/* <MicrosoftSocialLogin /> */}
      </div>
      <div className={classes.my15}>
        <Divider />
      </div>
    </div>
  );
};

export default SocialLogin;
