import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import CloseIcon from "@material-ui/icons/Close";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import FileInput from "components/FileInput/FileInput";
import Loader from "components/Loader";
import moment from "moment";
import {
  getSubjectDataFieldList,
  sendDRCRequest,
} from "pages/credentials/Credentials.action";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "store/commonActions";
import {
  getValidatorRules,
  objectsAreSame,
  onNumberFieldKeyDown,
  setInputType,
  trimObjValues,
} from "utils";
import { useLocation } from "react-router-dom";

//* Create Theme to Handle DatePicker
const datepickerTheme = createTheme({
  overrides: {
    MuiInput: {
      root: {
        marginBottom: 3,
      },
      underline: {
        "&:before": {
          content: "",
        },
        "&:after": {
          content: "",
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#083f85",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#083f85",
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#083f85",
      },
    },
    MuiButton: {
      textPrimary: {
        color: "#083f85",
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: "#083f85",
      },
    },
    MuiPickersClockNumber: {
      clockNumberSelected: {
        backgroundColor: "#083f85",
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: "#083f85",
      },
    },
    PrivateTabIndicator: {
      root: {
        height: "2px !important",
        borderRadius: 100,
        position: "absolute",
        backgroundColor: "#fff !important",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    maxHeight: 500,
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "block",
      width: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#083f85d1",
      borderRadius: 25,
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 400,
    },
  },
  dialogContainerWrapper: {
    maxWidth: 582,
    padding: "30px 40px",
    minWidth: 520,
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
    },
    "& .MuiDialogContent-root": {
      padding: "8px 0",
    },
  },
  dialogContentWrapper: {
    "& input": {
      borderRadius: "4px",
      border: "solid 0.5px #9f9f9f",
      backgroundColor: "#f6f6f6",
    },
  },
  fieldWrapper1: {
    marginBottom: 15,
  },
  fieldWrapper2: {
    marginBottom: 12,
    "&.MuiInput-underline:before": {
      height: "auto",
    },
  },
  fileInputWrapper: {
    width: "100%",
    marginBottom: 12,
  },
  fileInput: {
    border: "solid 0.5px #9f9f9f",
    borderRadius: "4px",
    padding: "10px 15px",
  },
  fileInputLable: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#083f85",
    marginBottom: "0",
    textTransform: "capitalize",
    "& span": {
      color: "#f00",
    },
  },
  inputLable: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#616161",
    marginBottom: "8px",
    textTransform: "capitalize",
    "& span": {
      color: "#f00",
    },
  },
  addButton: {
    color: "#ffffff",
    backgroundColor: "#083f85",
    borderRadius: "25px",
    padding: "8px 80px",
    textAlign: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#083f85",
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    padding: "15px 0",
    marginTop: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      "& button": {
        padding: "8px 25px",
      },
    },
  },
  addRecordForSubjectError: {
    marginTop: "15px",
    textAlign: "center",
    "& p": {
      color: "red",
      fontWeight: "550",
      fontSize: "12px",
    },
  },
  dialogPaper: {
    maxHeight: 400,
    "& .MuiDialogContent-root": {
      [theme.breakpoints.down("xs")]: {
        padding: 24,
      },
    },
  },

  backdrop: {
    color: "#fff",
    position: "absolute",
    zIndex: theme.zIndex.drawer - 1,
    opacity: 0.5,
  },

  inputProps: {
    padding: "11px 12px",
    backgroundColor: "#f6f6f6",
    border: "solid 0.5px #9f9f9f",
  },

  title: {
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    color: "#083f85",
    padding: "0 0 20px",
  },

  closeIconWrapper: {
    position: "absolute",
    top: 2,
    right: 10,
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      top: 0,
      right: 2,
    },
  },
  datePicker: {
    "& input": {
      color: "#616161",
      padding: "10px 15px",
      fontSize: "16px",
      height: 16,
      paddingRight: 35,
    },
  },
  invalidDate: {
    "& input": {
      border: "1px solid red",
    },
  },
  datePickerClearIcon: {
    position: "absolute",
    right: 0,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 8,
    },
  },
}));

function AddRecord(props) {
  const classes = useStyles();
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { credential } = location.state;
  const { loading } = useSelector(
    (state) => state.credential.sendDRCRequest || {}
  );
  const { loading: fieldLoading, response: allDataFields } = useSelector(
    (state) => state.credential.subjectDataField || {}
  );

  const methods = useForm({
    defaultValues: {
      subject_fields: [],
      // theme_additional_fields: [],
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = methods;
  // const subjectFields = watch("subject_fields");
  // const themeAdditionalFields = watch("theme_additional_fields");
  const subjectFields =
    allDataFields?.subject_fields?.filter(
      ({ is_holder_editable }) => is_holder_editable
    ) || [];
  // const themeAdditionalFields = allDataFields?.theme_additional_fields;

  useEffect(() => {
    if (Object.keys(allDataFields)) {
      const duplicateRecord = {};

      let defaultSubjectFields = {};
      // let defaultAdditionalFields = {};
      subjectFields?.forEach((elem) => {
        const key = elem.fname;
        const nameKey = key === "name" ? "candidate_name" : elem.fname;
        defaultSubjectFields[key] =
          duplicateRecord?.[key === "email" ? "candidate_email" : nameKey] ??
          elem.value ??
          "";
      });

      // allDataFields?.theme_additional_fields?.forEach((elem) => {
      //   const key = elem.fname;
      //   defaultAdditionalFields[key] =
      //     duplicateRecord?.additional_slug?.[key] ?? elem.value ?? "";
      // });

      reset({
        subject_fields: defaultSubjectFields || {},
        // theme_additional_fields: defaultAdditionalFields || {},
      });
    }
    // eslint-disable-next-line
  }, [allDataFields, reset]);

  useEffect(() => {
    dispatch(getSubjectDataFieldList({ params: {} }));
  }, [reset, dispatch]);

  const submitRecordForDetail = (data) => {
    const successCB = () => {
      dispatch(
        openNotification({
          message: "DRC Requested successfully!",
        })
      );
      handleClose();
      reset();
    };
    const failureCB = (message) => {
      dispatch(
        openNotification({
          message,
          severity: "error",
        })
      );
    };

    const subject_fields = data?.subject_fields;
    // const theme_additional_fields = data?.theme_additional_fields;
    const jsonData = {
      slug: JSON.stringify({
        subject_fields: trimObjValues(subject_fields),
        // theme_additional_fields: trimObjValues(theme_additional_fields),
      }),
      cred_id: credential?.id,
    };

    dispatch(sendDRCRequest({ payload: jsonData, successCB, failureCB }));
  };

  const handleDateFieldClear = (event, name) => {
    event.stopPropagation();
    setValue(name, null, {
      shouldValidate: true,
    });
  };

  const getFormFields = (fieldData, index, name) => {
    const fieldLabel = fieldData.fname.replaceAll("_", " ");
    const fieldName = `${name}.${fieldData.fname}`;
    const fieldType = setInputType(fieldData.ftype, fieldData.fname);
    const rules = getValidatorRules(
      fieldType,
      fieldData.is_mandatory,
      fieldData.ftype,
      fieldData.fname
    );

    switch (fieldType) {
      case "file":
        return (
          <div className={classes.fileInputWrapper}>
            <InputLabel
              className={classes.inputLable}
              required={fieldData.is_mandatory}
            >
              {fieldLabel}
            </InputLabel>
            <FileInput fieldName={fieldName} fieldData={fieldData} />
          </div>
        );
      case "date":
        return (
          <div className={classes.fieldWrapper2}>
            <InputLabel
              htmlFor={fieldName}
              className={classes.inputLable}
              required={fieldData.is_mandatory}
            >
              {fieldLabel}
            </InputLabel>

            <Controller
              name={fieldName}
              control={control}
              rules={rules}
              defaultValue={null}
              render={({ field, fieldState }) => (
                <ThemeProvider theme={datepickerTheme}>
                  <DatePicker
                    {...field}
                    inputRef={field?.ref}
                    className={
                      fieldState.invalid
                        ? [classes.datePicker, classes.invalidDate].join(" ")
                        : classes.datePicker
                    }
                    placeholder={fieldData.sample}
                    format="DD MMMM yyyy"
                    variant="dialog"
                    fullWidth
                    onChange={(value) => {
                      const formattedDate = moment(value).format("YYYY-MM-DD");
                      field.onChange(formattedDate);
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState?.error?.message || ""}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          className={classes.datePickerClearIcon}
                          onClick={(event) =>
                            handleDateFieldClear(
                              event,
                              `${name}.${index}.${fieldData.fname}`
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </ThemeProvider>
              )}
            />
          </div>
        );
      case "datetime":
        return (
          <div className={classes.fieldWrapper2}>
            <InputLabel
              htmlFor={fieldName}
              className={classes.inputLable}
              required={fieldData.is_mandatory}
            >
              {fieldLabel}
            </InputLabel>
            <Controller
              name={fieldName}
              control={control}
              rules={rules}
              defaultValue={null}
              render={({ field, fieldState }) => (
                <ThemeProvider theme={datepickerTheme}>
                  <DateTimePicker
                    {...field}
                    inputRef={field?.ref}
                    className={
                      fieldState.invalid
                        ? [classes.datePicker, classes.invalidDate].join(" ")
                        : classes.datePicker
                    }
                    placeholder={fieldData.sample}
                    format="DD MMMM yyyy - LT"
                    variant="dialog"
                    fullWidth
                    onChange={(value) => {
                      const formattedDate = moment(value).format();
                      field.onChange(formattedDate);
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState?.error?.message || ""}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          className={classes.datePickerClearIcon}
                          onClick={(event) =>
                            handleDateFieldClear(
                              event,
                              `${name}.${index}.${fieldData.fname}`
                            )
                          }
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </ThemeProvider>
              )}
            />
          </div>
        );
      default:
        return (
          <div className={classes.fieldWrapper2}>
            <InputLabel
              htmlFor={fieldName}
              className={classes.inputLable}
              required={fieldData.is_mandatory}
            >
              {fieldLabel}
            </InputLabel>
            <Controller
              name={fieldName}
              control={control}
              rules={rules}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  inputRef={field.ref}
                  placeholder={fieldData.sample}
                  type={fieldType}
                  error={fieldState.invalid}
                  helperText={fieldState?.error?.message || ""}
                  onChange={(ev) => {
                    field.onChange(ev.target.value.trimStart());
                  }}
                  onKeyDown={(ev) =>
                    onNumberFieldKeyDown(
                      ev,
                      setInputType(fieldData?.ftype, fieldData?.fname),
                      fieldData?.ftype
                    )
                  }
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
          </div>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(event) => {
        reset();
        handleClose(event);
      }}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialogContainer }}
    >
      <div className={classes.dialogContainerWrapper}>
        <IconButton
          className={classes.closeIconWrapper}
          onClick={(event) => {
            reset();
            handleClose(event);
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContentWrapper}>
          <Typography variant="h4" component="p" className={classes.title}>
            DRC Details
          </Typography>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submitRecordForDetail)}>
              {subjectFields?.map((field, index) => (
                <React.Fragment key={field.id}>
                  {getFormFields(field, index, "subject_fields")}
                </React.Fragment>
              ))}

              {/* {Boolean(themeAdditionalFields?.length) && (
                <Typography
                  color="primary"
                  variant="subtitle1"
                  component="p"
                  className={classes.fieldWrapper2}
                >
                  Additional Fields
                </Typography>
              )}

              {themeAdditionalFields?.map((field, index) => (
                <React.Fragment key={field?.id}>
                  {getFormFields(field, index, "theme_additional_fields")}
                </React.Fragment>
              ))} */}

              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.addButton}
                  disabled={loading || !isDirty}
                >
                  <Loader show={loading} />
                  {!loading ? "Send" : null}
                </Button>
              </div>
            </form>
          </FormProvider>
        </DialogContent>
        <Backdrop className={classes.backdrop} open={fieldLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </Dialog>
  );
}

function areEqual(prevProps, nextProps) {
  return objectsAreSame(prevProps, nextProps);
}

export default React.memo(AddRecord, areEqual);
