import { Button, makeStyles } from "@material-ui/core";
import { useState } from "react";
import AddRecord from "./components/AddRecord";
import TabsNavigation from "./components/TabsNavigation";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  userActionContainer: {
    background: "#fbfcfe",
    zIndex: 2,
    position: "relative",
    borderRadius: "15px 15px 0 0",
    padding: "30px 20px 0",
    margin: "-15px 10px 0 ",
    [theme.breakpoints.down("sm")]: {
      margin: "-15px 0px 0",
    },
  },
  requestDRCBtn: {
    padding: 10,
    width: "100%",
  },
}));
export default function CredentialsDetailsBody() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { credential } = location.state;

  function handleClose() {
    setOpen(false);
  }
  const isDRC = !!credential?.previous_cred_id;

  return (
    <>
      <div className={classes.userActionContainer}>
        {!isDRC && (
          <div>
            <Button
              className={classes.requestDRCBtn}
              color="primary"
              variant="contained"
              type="submit"
              onClick={() => setOpen(true)}
            >
              Request DRC
            </Button>
          </div>
        )}
        <AddRecord open={open} handleClose={handleClose} />
        <TabsNavigation />
      </div>
    </>
  );
}
