import { openNotification } from "store/commonActions";
import { downloadFile, headers, http } from "utils";
import {
  ADD_CREDENTIAL_IMAGE_BEGIN,
  ADD_CREDENTIAL_IMAGE_FAILURE,
  ADD_CREDENTIAL_IMAGE_SUCCESS,
  CLEAR_CREDENTIAL_REDUCER,
  CREDENTIAL_PREVIEW_BEGIN,
  CREDENTIAL_PREVIEW_FAILURE,
  CREDENTIAL_PREVIEW_SUCCESS,
  DOWNLOAD_CREDENTIAL_BEGIN,
  DOWNLOAD_CREDENTIAL_FAILURE,
  DOWNLOAD_CREDENTIAL_SUCCESS,
  GET_CREDENTIAL_LIST_BEGIN,
  GET_CREDENTIAL_LIST_FAILURE,
  GET_CREDENTIAL_LIST_SUCCESS,
  GET_ISSUER_LIST_BEGIN,
  GET_ISSUER_LIST_FAILURE,
  GET_ISSUER_LIST_SUCCESS,
  GET_LAST_VERIFIED_DATE_BEGIN,
  GET_LAST_VERIFIED_DATE_FAILURE,
  GET_LAST_VERIFIED_DATE_SUCCESS,
  GET_RECENTLY_VERIFIED_CREDENTIAL_BEGIN,
  GET_RECENTLY_VERIFIED_CREDENTIAL_FAILURE,
  GET_RECENTLY_VERIFIED_CREDENTIAL_SUCCESS,
  GET_SUBJECT_DATA_FIELD_BEGIN,
  GET_SUBJECT_DATA_FIELD_FAILURE,
  GET_SUBJECT_DATA_FIELD_SUCCESS,
  GET_VERIFICATION_OVERVIEW_BEGIN,
  GET_VERIFICATION_OVERVIEW_FAILURE,
  GET_VERIFICATION_OVERVIEW_SUCCESS,
  LOAD_MORE_ISSUER_BEGIN,
  LOAD_MORE_ISSUER_FAILURE,
  LOAD_MORE_ISSUER_SUCCESS,
  SELECT_CREDENTIAL,
  SELECT_ISSUER,
  SEND_DRC_REQUEST_BEGIN,
  SEND_DRC_REQUEST_FAILURE,
  SEND_DRC_REQUEST_SUCCESS,
  SHARE_CREDENTIAL_BEGIN,
  SHARE_CREDENTIAL_FAILURE,
  SHARE_CREDENTIAL_SUCCESS,
} from "./Credentials.constant";

// Get Data Field List
const getSubjectDataFieldBegin = () => ({
  type: GET_SUBJECT_DATA_FIELD_BEGIN,
});

const getSubjectDataFieldSuccess = (payload) => ({
  type: GET_SUBJECT_DATA_FIELD_SUCCESS,
  payload,
});
const getSubjectDataFieldFailure = (payload) => ({
  type: GET_SUBJECT_DATA_FIELD_FAILURE,
  payload,
});

export const getSubjectDataFieldList =
  ({ successCB = () => {} }) =>
  (dispatch) => {
    dispatch(getSubjectDataFieldBegin());
    http("get", "/user/subject/fields", null, { headers }, true)
      .then((response) => {
        dispatch(getSubjectDataFieldSuccess(response.data.data));
        successCB(response.data.data);
      })
      .catch((error) => {
        dispatch(getSubjectDataFieldFailure(error.response?.data?.message));
      });
  };

//Send DRC Request
const sendDRCRequestBegin = () => ({
  type: SEND_DRC_REQUEST_BEGIN,
});

const sendDRCRequestSuccess = (payload) => ({
  type: SEND_DRC_REQUEST_SUCCESS,
  payload,
});
const sendDRCRequestFailure = (payload) => ({
  type: SEND_DRC_REQUEST_FAILURE,
  payload,
});

export const sendDRCRequest =
  ({ payload, successCB, failureCB }) =>
  (dispatch) => {
    dispatch(sendDRCRequestBegin());
    http("post", "/user/subject/drc/record", payload, { headers }, true)
      .then((response) => {
        dispatch(sendDRCRequestSuccess(response.data.data));
        successCB();
      })
      .catch((error) => {
        dispatch(sendDRCRequestFailure(error.response?.data?.message));
        failureCB(error.response?.data?.message);
      });
  };

// Get Issuer List
const getIssuerListBegin = () => ({
  type: GET_ISSUER_LIST_BEGIN,
});

const getIssuerListSuccess = (payload) => ({
  type: GET_ISSUER_LIST_SUCCESS,
  payload,
});
const getIssuerListFailure = (payload) => ({
  type: GET_ISSUER_LIST_FAILURE,
  payload,
});

export const getIssuerList = (params) => (dispatch) => {
  dispatch(getIssuerListBegin());
  http("get", "/holder/issuers", null, { headers, params }, true)
    .then((response) => {
      dispatch(getIssuerListSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(getIssuerListFailure(error.response?.data?.message));
    });
};

// Get Credential List
const getCredentialsListBegin = () => ({
  type: GET_CREDENTIAL_LIST_BEGIN,
});

const getCredentialsListSuccess = (payload) => ({
  type: GET_CREDENTIAL_LIST_SUCCESS,
  payload,
});
const getCredentialsListFailure = (payload) => ({
  type: GET_CREDENTIAL_LIST_FAILURE,
  payload,
});

export const getCredentialsList = (params) => (dispatch) => {
  dispatch(getCredentialsListBegin());
  http("get", "/holder/issuer/credentials", null, { headers, params }, true)
    .then((response) => {
      dispatch(getCredentialsListSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(getCredentialsListFailure(error.response?.data?.message));
    });
};

export const selectIssuer = (payload) => ({
  type: SELECT_ISSUER,
  payload,
});

export const selectCredential = (payload) => ({
  type: SELECT_CREDENTIAL,
  payload,
});

//Load More Issuer
const loadMoreIssuerBegin = () => ({
  type: LOAD_MORE_ISSUER_BEGIN,
});

const loadMoreIssuerSuccess = (payload) => ({
  type: LOAD_MORE_ISSUER_SUCCESS,
  payload,
});
const loadMoreIssuerFailure = (payload) => ({
  type: LOAD_MORE_ISSUER_FAILURE,
  payload,
});

export const loadMoreIssuer = (params) => (dispatch) => {
  dispatch(loadMoreIssuerBegin());
  http("get", "/holder/issuers", null, { headers, params }, true)
    .then((response) => {
      dispatch(loadMoreIssuerSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(loadMoreIssuerFailure(error.response?.data?.message));
    });
};

export const clearCredentialReducer = () => ({
  type: CLEAR_CREDENTIAL_REDUCER,
});

//Preview
const credentialsPreviewBegin = () => ({
  type: CREDENTIAL_PREVIEW_BEGIN,
});

const credentialsPreviewSuccess = (payload) => ({
  type: CREDENTIAL_PREVIEW_SUCCESS,
  payload,
});

const credentialsPreviewFailure = (payload) => ({
  type: CREDENTIAL_PREVIEW_FAILURE,
  payload,
});

export const previewCredential = (params) => (dispatch) => {
  dispatch(credentialsPreviewBegin());
  http("get", "/holder/credential/preview", null, { headers, params }, true)
    .then((response) => {
      dispatch(credentialsPreviewSuccess(response.data.data));
    })
    .catch((error) => {
      dispatch(credentialsPreviewFailure(error.response?.data?.message));
    });
};

// Download Credential
const downloadCredentialBegin = () => ({
  type: DOWNLOAD_CREDENTIAL_BEGIN,
});

const downloadCredentialSuccess = (payload) => ({
  type: DOWNLOAD_CREDENTIAL_SUCCESS,
  payload,
});
const downloadCredentialFailure = (payload) => ({
  type: DOWNLOAD_CREDENTIAL_FAILURE,
  payload,
});

export const downloadCredential = (cred_id) => (dispatch) => {
  dispatch(downloadCredentialBegin());
  http("get", `/user/credential/${cred_id}/download`, null, { headers }, true)
    .then((response) => {
      dispatch(downloadCredentialSuccess(response.data.data));
      downloadFile(response.data.data.credential);
    })
    .catch((error) => {
      dispatch(downloadCredentialFailure(error.response?.data?.message));
      dispatch(
        openNotification({
          message: error?.response?.data?.message,
          severity: "error",
        })
      );
    });
};

const shareCredentialBegin = () => ({
  type: SHARE_CREDENTIAL_BEGIN,
});

const shareCredentialSuccess = (payload) => ({
  type: SHARE_CREDENTIAL_SUCCESS,
  payload,
});
const shareCredentialFailure = (payload) => ({
  type: SHARE_CREDENTIAL_FAILURE,
  payload,
});

export const shareCredential = (cert_id, email, successCB) => (dispatch) => {
  dispatch(shareCredentialBegin());
  http(
    "post",
    `/holder/credential/share?credential_id=${cert_id}`,
    email,
    { headers },
    true
  )
    .then((response) => {
      dispatch(shareCredentialSuccess(response.data));
      dispatch(
        openNotification({
          message: "Credential has been shared successfully!",
        })
      );
      successCB(response.data);
    })
    .catch((error) => {
      dispatch(shareCredentialFailure(error.response.data.message));
      dispatch(
        openNotification({
          message: error?.response?.data?.message,
          severity: "error",
        })
      );
    });
};

//INSIGHT

const getVerificationOverviewBegin = () => ({
  type: GET_VERIFICATION_OVERVIEW_BEGIN,
});
const getVerificationOverviewSuccess = (payload) => ({
  type: GET_VERIFICATION_OVERVIEW_SUCCESS,
  payload,
});

const getVerificationOverviewFailure = (payload) => ({
  type: GET_VERIFICATION_OVERVIEW_FAILURE,
  payload,
});

export const getVerificationOverview = (params) => (dispatch) => {
  dispatch(getVerificationOverviewBegin());
  http("get", "/user/credential/stats", null, { headers, params }, true)
    .then((response) => {
      dispatch(getVerificationOverviewSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getVerificationOverviewFailure());
    });
};

const getVerificationLogBegin = () => ({
  type: GET_RECENTLY_VERIFIED_CREDENTIAL_BEGIN,
});
const getVerificationLogSuccess = (payload) => ({
  type: GET_RECENTLY_VERIFIED_CREDENTIAL_SUCCESS,
  payload,
});

const getVerificationLogFailure = (payload) => ({
  type: GET_RECENTLY_VERIFIED_CREDENTIAL_FAILURE,
  payload,
});

export const getVerificationLog = (params) => (dispatch) => {
  dispatch(getVerificationLogBegin());
  http("get", "/user/analytics/credential", null, { headers, params }, true)
    .then((response) => {
      dispatch(getVerificationLogSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getVerificationLogFailure());
    });
};

// GET LAST VERIFIED DATE

const getLastVerifiedDateBegin = () => ({
  type: GET_LAST_VERIFIED_DATE_BEGIN,
});
const getLastVerifiedDateSuccess = (payload) => ({
  type: GET_LAST_VERIFIED_DATE_SUCCESS,
  payload,
});

const getLastVerifiedDateFailure = (payload) => ({
  type: GET_LAST_VERIFIED_DATE_FAILURE,
  payload,
});

export const getLastVerifiedDate = (params) => (dispatch) => {
  dispatch(getLastVerifiedDateBegin());
  http("get", "/user/credential/last_verified", null, { headers, params }, true)
    .then((response) => {
      dispatch(getLastVerifiedDateSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getLastVerifiedDateFailure(err.response.data.message));
    });
};

// ADD CREDENTIAL IMAGE

const addCredentialImageBegin = () => ({
  type: ADD_CREDENTIAL_IMAGE_BEGIN,
});
const addCredentialImageSuccess = (payload) => ({
  type: ADD_CREDENTIAL_IMAGE_SUCCESS,
  payload,
});

const addCredentialImageFailure = (payload) => ({
  type: ADD_CREDENTIAL_IMAGE_FAILURE,
  payload,
});

export const addCredentialImage =
  (credentialId, payload, successCB) => (dispatch) => {
    dispatch(addCredentialImageBegin());
    http(
      "put",
      "/holder/edit/fields/" + credentialId,
      payload,
      { headers },
      true
    )
      .then((response) => {
        dispatch(addCredentialImageSuccess(response.data.data));
        dispatch(
          openNotification({
            message: response.data.message,
          })
        );
        successCB();
      })
      .catch((err) => {
        dispatch(addCredentialImageFailure(err.response.data.message));
        dispatch(
          openNotification({
            message: err?.response?.data?.message,
            severity: "error",
          })
        );
      });
  };
